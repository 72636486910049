import jsPDF from "jspdf";
import React from "react";
import { fonty } from "../../utils/Amiri-Regular-normal.js";

export default function Table(props) {
  const days = [
    {
      ar: "الاحد",
      en: "Sunday",
    },
    {
      ar: "الاثنين",
      en: "Monday",
    },
    {
      ar: "الثلاثاء",
      en: "Tuesday",
    },
    {
      ar: "الاربع",
      en: "Wednesday",
    },
    {
      ar: "الخميس",
      en: "Thursday",
    },
  ];
  let sech = props.ta;

  const listDays = days.map((e) => {
    let day = sech;
    day = day.filter((el) => el.day === e.en);
    let time = [];
    if (day.length > 0) {
      time = day[0].info;
    }
    // console.log(day)

    return (
      <>
        <tr>
          <td rowSpan={3} className="border p-2 text-center">
            {e.ar}
          </td>
          <td className="border p-2 text-center">الكورس</td>
          <div className=" border grid grid-cols-9">
            {time
              .sort((a, b) => a.timeSlotStart - b.timeSlotStart)
              .map((e, index) => {
                return (
                  <td
                    key={index}
                    style={{
                      "grid-column-start": `${e.timeStart - 7}`,
                      "grid-column-end": `${e.timeEnd - 7}`,
                    }}
                    className={`border p-2 text-center`}
                  >
                    {e.course}
                  </td>
                );
              })}
          </div>
        </tr>

        <tr>
          <td className="border p-2 text-center">القسم</td>
          <div className=" border grid grid-cols-9">
            {time
              .sort((a, b) => a.timeSlotStart - b.timeSlotStart)
              .map((e, index) => {
                return (
                  <td
                    key={index}
                    style={{
                      "grid-column-start": `${e.timeStart - 7}`,
                      "grid-column-end": `${e.timeEnd - 7}`,
                    }}
                    className={`border p-2 text-center`}
                  >
                    {e.department}
                  </td>
                );
              })}
          </div>
        </tr>
        <tr>
          <td className="border p-2 text-center">المستوى</td>
          <div className=" border grid grid-cols-9">
            {time
              .sort((a, b) => a.timeSlotStart - b.timeSlotStart)
              .map((e, index) => {
                return (
                  <td
                    key={index}
                    style={{
                      "grid-column-start": `${e.timeStart - 7}`,
                      "grid-column-end": `${e.timeEnd - 7}`,
                    }}
                    className={`border p-2 text-center`}
                  >
                    {e.level}
                  </td>
                );
              })}
          </div>
        </tr>
      </>
    );
  });

  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a2");
    // pdf.addFileToVFS("Amiri-Regular-normal.ttf", myFont);
    // pdf.addFont("Amiri-Regular-normal.ttf", "MyFont", "normal");
    pdf.setFont("Amiri-Regular");
    const data = await document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("جدول.pdf");
    });
  };

  return (
    <div>
      <div className="w-full my-10 overflow-x-auto">
        <button onClick={createPDF} className="btn">
          تحميل pdf <i class="fa-solid fa-file-pdf"></i>
        </button>
        <div className=" px-2" id="pdf">
          <div className=" ar-ta flex font-bold text-center items-center justify-between">
            <section className="">
              <h1>المملكة العربية السعودية</h1>
              <h1>وزارة التعليم</h1>
              <h1>جامعة نجران</h1>
            </section>
            <section>
              <h1>الجدول الدراسي للمعلم {props.prof}</h1>
            </section>
            <section>
              <img alt="logo" className=" h-24" src="./imgs/logo.jpg" />
            </section>
          </div>
          <table className="table rtl ar-ta text-xs">
            <thead>
              <tr className=" border rtl">
                <td className="border p-2 text-center">اليوم</td>
                <td className="border p-2 text-center">البيانات</td>
                <div className=" grid grid-cols-9">
                  <td className="border p-2 text-center">9-8</td>
                  <td className="border p-2 text-center">10-9</td>
                  <td className="border p-2 text-center">11-10</td>
                  <td className="border p-2 text-center">12-11</td>
                  <td className="border p-2 text-center">1-12</td>
                  <td className="border p-2 text-center">2-1</td>
                  <td className="border p-2 text-center">3-2</td>
                  <td className="border p-2 text-center">4-3</td>
                  <td className="border p-2 text-center">5-4</td>
                </div>
              </tr>
            </thead>
            <tbody>{listDays}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

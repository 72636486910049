import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Courses() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [reload, setReload] = useState(false);

  //
  // Add Course
  //
  const [course, setCourse] = useState({
    name: null,
    level: null,
    department: null,
    lecHours: null,
    secHours: null,
    terms: null,
  });

  // optins
  const [levels, setLevels] = useState([]);
  const [department, setDeparments] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getDepartment`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setDeparments(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });

    fetch(`${process.env.REACT_APP_URL}/api/auth/getLevel`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLevels(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, []);

  const addHandeller = (e) => {
    e.preventDefault();
    setLoad(true);

    var formdata = new FormData();
    formdata.append("name", course.name);
    formdata.append("level", course.level);
    formdata.append("department", course.department);
    formdata.append("totalHourOfLec", course.lecHours);
    formdata.append("totalHourOfSection", course.secHours);
    course.terms.includes("one") && formdata.append("terms[]", "one");
    course.terms.includes("two") && formdata.append("terms[]", "two");

    // formdata.append("terms[]", "two");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_URL}/api/auth/AddCourse`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setReload(!reload);
        setCourse({
          name: "",
          level: "",
          department: "",
          lecHours: "",
          secHours: "",
          terms: "",
        });
      }
    });
  };

  //
  // Dlete Level
  //
  const deleteHandeller = (id) => {
    setLoad(true);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_URL}/api/auth/deleteCourse/${id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setReload(!reload);
      }
    });
  };

  // Rows

  const [open, setOpen] = useState(null);

  const Row = (props) => {
    const da = props.el;
    return (
      <div>
        <tr
          className={`grid overflow-hidden grid-cols-12 border-b transition-all ease-in-out duration-500 ${
            open === da.id ? "h-32 " : "h-11 "
          }`}
        >
          <td className=" col-span-1 text-center p-2">
            <button
              onClick={() => {
                if (open !== da.id) {
                  setOpen(da.id);
                } else {
                  setOpen(null);
                }
              }}
            >
              {open === da.id ? (
                <i class="fa-solid rotate-180 fa-chevron-down"></i>
              ) : (
                <i class="fa-solid fa-chevron-down"></i>
              )}
            </button>
          </td>
          <td className=" col-span-10 text-start p-2">{da.name}</td>
          {/* <td className=" col-span-1 text-center p-2">تتت</td> */}
          <td className=" col-span-1 text-center p-2">
            <button
              disabled={load}
              onClick={() => deleteHandeller(da.id)}
              className=" px-3 text-red-700/80 hover:text-red-700"
            >
              <i className="fa-solid fa-trash"></i>
            </button>
          </td>
          <div className={`p-2 col-span-12`}>
            <table className={`text-sm w-full overflow-hidden`}>
              <thead>
                <tr className="">
                  <th className=" col-span-9 text-start p-2">
                    ساعات المحاضرات
                  </th>
                  <th className=" col-span-9 text-start p-2">ساعات السكاشن</th>
                  <th className=" col-span-9 text-start p-2"> المستوى</th>
                  <th className=" col-span-9 text-start p-2"> القسم</th>
                  <th className=" col-span-9 text-start p-2"> الفصول</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4">{da.totalHourOfLec}</td>
                  <td className="px-4">{da.totalHourOfSection}</td>
                  <td className="px-4">{da.level}</td>
                  <td className="px-4">{da.department}</td>
                  <td className="px-4">
                    {da.terms.includes("one") && "الاول - "}
                    {da.terms.includes("two") && "الثاني"}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </tr>
      </div>
    );
  };

  //
  // Fetching data
  //

  const [courses, setCourses] = useState([]);
  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getCourse`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setCourses(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, [reload]);
  return (
    <div className="p-4">
      <h1 className="head">الكورسات</h1>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      <form className="form">
        <div className="formItem">
          <TextField
            value={course.name}
            onChange={(e) => setCourse({ ...course, name: e.target.value })}
            id="outlined-basic"
            label="اضافة كورس"
            variant="outlined"
          />
        </div>
        <div className=" min-w-56 p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">المستوى</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={course.level}
              label="المستوى"
              onChange={(e) => setCourse({ ...course, level: e.target.value })}
            >
              {levels.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الترم</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={course.terms}
              label="الترم"
              onChange={(e) => setCourse({ ...course, terms: e.target.value })}
            >
              <MenuItem value={"one"}>الاول</MenuItem>
              <MenuItem value={"two"}>الثاني</MenuItem>
              <MenuItem value={"one and two"}>الاول والثاني</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">القسم</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={course.department}
              label="القسم"
              onChange={(e) =>
                setCourse({ ...course, department: e.target.value })
              }
            >
              {department.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <TextField
            id="outlined-number"
            label="ساعات المحاضرات"
            type="number"
            value={course.lecHours}
            onChange={(e) => setCourse({ ...course, lecHours: e.target.value })}
          />
        </div>
        <div className="min-w-56  p-2">
          <TextField
            id="outlined-number"
            label="ساعات السكاشن"
            type="number"
            value={course.secHours}
            onChange={(e) => setCourse({ ...course, secHours: e.target.value })}
          />
        </div>
        <div className="formItem">
          <button
            onClick={addHandeller}
            disabled={
              load ||
              !course.name ||
              !course.level ||
              !course.department ||
              !course.terms ||
              !course.lecHours ||
              !course.secHours
            }
            className=" btn "
          >
            {!load ? (
              <>
                {" "}
                اضافة <span className=" text-2xl font-bold">+</span>
              </>
            ) : (
              <div className=" flex ">
                تحميل{" "}
                <div class="dots mx-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </button>
        </div>
      </form>
      <hr className=" w-3/4 mx-auto my-4"></hr>

      {error ? (
        <>
          <h1 className=" text-red-600 text-center my-48 text-4xl">
            هناك مشكلة .....
          </h1>
        </>
      ) : (
        <table className="table">
          <thead>
            <tr className=" grid grid-cols-12 bg-gray-100 border-b">
              <th className=" col-span-1 text-start p-2"></th>
              <th className=" col-span-10 text-start p-2">الكورس</th>
              {/* <th className=" col-span-1 text-center p-2">تعديل</th> */}
              <th className=" col-span-1 text-center p-2">حذف</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((e) => {
              return <Row key={e.id} el={e} />;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

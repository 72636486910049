import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Profes() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [reload, setReload] = useState(false);

  //
  // Dlete Level
  //
  const deleteHandeller = (id) => {
    setLoad(true);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_URL}/api/auth/deleteProf/${id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setReload(!reload);
      } else {
        setLoad(false);
        setError(true);
      }
    });
  };

  const [profs, setProfs] = useState([]);

  // Rows

  const [open, setOpen] = useState(null);

  const Row = (props) => {
    const da = props.el;
    return (
      <tr
        className={`grid overflow-hidden grid-cols-12 border-b transition-all ease-in-out duration-500 ${
          open === da.id ? "h-fit " : "h-11 "
        }`}
      >
        <td className=" col-span-1 text-center p-2">
          <button
            onClick={() => {
              if (open !== da.id) {
                setOpen(da.id);
              } else {
                setOpen(null);
              }
            }}
          >
            {open === da.id ? (
              <i class="fa-solid rotate-180 fa-chevron-down"></i>
            ) : (
              <i class="fa-solid fa-chevron-down"></i>
            )}
          </button>
        </td>
        <td className=" col-span-10 text-start p-2">{da.name}</td>
        {/* <td className=" col-span-1 text-center p-2">تتت</td> */}
        <td className=" col-span-1 text-center p-2">
          <button
            disabled={load}
            onClick={() => deleteHandeller(da.id)}
            className=" px-3 text-red-700/80 hover:text-red-700"
          >
            <i className="fa-solid fa-trash"></i>
          </button>
        </td>
        <div className={`p-2 col-span-12`}>
          <table className=" text-sm w-full">
            <thead>
              <tr className="">
                <th className=" col-span-9 text-start p-2">
                  عدد الساعات الفعلي
                </th>
                <th className=" col-span-9 text-start p-2"> عدد سعات العمل</th>
                <th className=" col-span-9 text-start p-2"> الايميل</th>
                <th className=" col-span-9 text-start p-2"> رقم التليفون</th>
              </tr>
            </thead>
            <tbody>
              <tr className=" border-b">
                <td className="px-4">{da.academic_hours}</td>
                <td className="px-4">{da.work_hours}</td>
                <td className="px-4">{da.email}</td>
                <td className="px-4">{da.phone_number}</td>
              </tr>
              <tr className=" p-1 border-b">
                <td className=" p-1 font-bold">المستويات :</td>
                {da.Level.map((e) => {
                  return (
                    <>
                      <td key={e.id}>{e.levels}</td>
                    </>
                  );
                })}
              </tr>
              <tr className=" p-1 border-b">
                <td className=" p-1 font-bold">الاقسام :</td>
                {da.Department.map((e) => {
                  return (
                    <>
                      <td key={e.id}>{e.department}</td>
                    </>
                  );
                })}
              </tr>
              <tr className=" p-1">
                <td className=" p-1 font-bold">الكورسات :</td>
                {da.Courses.map((e) => {
                  return (
                    <>
                      <td key={e.id}>{e.courses}</td>
                    </>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </tr>
    );
  };

  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getProf`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setProfs(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, [reload]);

  //
  // Add
  //

  const [courses, setCourses] = useState([]);
  const [levels, setLevels] = useState([]);
  const [departments, setDeparments] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getCourse`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setCourses(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });

    fetch(`${process.env.REACT_APP_URL}/api/auth/getLevel`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLevels(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });

    fetch(`${process.env.REACT_APP_URL}/api/auth/getDepartment`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setDeparments(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, []);

  const [prof, setProf] = useState({
    name: "",
    mail: "",
    phone: "",
    courses: [],
    levels: [],
    departments: [],
    hours: null,
  });

  const addHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const el = {
      name: prof.name,
      active: "true",
      email: prof.mail,
      phone_number: prof.phone,
      myCourses: prof.courses,
      myLevels: prof.levels,
      academic_hours: prof.hours,
      /*"work_hours",*/
      department: prof.departments,
      /*"lastWorkDay", 
      "lastWorkTime":8*/
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(el),
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URL}/api/auth/AddProf`, requestOptions).then(
      (res) => {
        if (res.status === 200) {
          setError(false);
          setLoad(false);
          setReload(!reload);
          setProf({
            name: "",
            mail: "",
            phone: "",
            courses: [],
            levels: [],
            departments: [],
            hours: "",
          });
        }
      }
    );
  };
  return (
    <div className="p-4">
      <h1 className="head">المعلمون</h1>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      <form className="form">
        <div className="formItem">
          <TextField
            value={prof.name}
            onChange={(e) => setProf({ ...prof, name: e.target.value })}
            id="outlined-basic"
            label="اضافة معلم"
            variant="outlined"
          />
        </div>
        <div className="formItem">
          <TextField
            value={prof.mail}
            onChange={(e) => setProf({ ...prof, mail: e.target.value })}
            id="outlined-basic"
            label="الايميل"
            variant="outlined"
          />
        </div>
        <div className="formItem">
          <TextField
            value={prof.phone}
            onChange={(e) => setProf({ ...prof, phone: e.target.value })}
            id="outlined-basic"
            label="التليفون"
            variant="outlined"
          />
        </div>
        <div className="w-56   p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الكورسات</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={prof.courses}
              renderValue={(selected) => selected.join(", ")}
              label="الكورسات"
              onChange={(e) => setProf({ ...prof, courses: e.target.value })}
            >
              {courses.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  <Checkbox checked={prof.courses.indexOf(e.name) > -1} />
                  <ListItemText primary={e.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-56   p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الاقسام</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={prof.departments}
              renderValue={(selected) => selected.join(", ")}
              label="الاقسام"
              onChange={(e) =>
                setProf({ ...prof, departments: e.target.value })
              }
            >
              {departments.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  <Checkbox checked={prof.departments.indexOf(e.name) > -1} />
                  <ListItemText primary={e.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-56   p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">المستويات</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={prof.levels}
              renderValue={(selected) => selected.join(", ")}
              label="المستويات"
              onChange={(e) => setProf({ ...prof, levels: e.target.value })}
            >
              {levels.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  <Checkbox checked={prof.levels.indexOf(e.name) > -1} />
                  <ListItemText primary={e.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <TextField
            id="outlined-number"
            label="ساعات العمل"
            type="number"
            value={prof.hours}
            onChange={(e) => setProf({ ...prof, hours: e.target.value })}
          />
        </div>
        <div className="formItem">
          <button
            onClick={addHandeller}
            disabled={
              load ||
              !prof.name ||
              // !prof.mail ||
              // !prof.phone ||
              !prof.hours ||
              prof.courses.length === 0 ||
              prof.departments.length === 0 ||
              prof.levels.length === 0
            }
            className=" btn "
          >
            {!load ? (
              <>
                {" "}
                اضافة <span className=" text-2xl font-bold">+</span>
              </>
            ) : (
              <div className=" flex ">
                تحميل{" "}
                <div class="dots mx-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </button>
        </div>
      </form>
      <hr className=" w-3/4 mx-auto my-4"></hr>

      {error ? (
        <>
          <h1 className=" text-red-600 text-center my-48 text-4xl">
            هناك مشكلة .....
          </h1>
        </>
      ) : (
        <table className="table">
          <thead>
            <tr className=" grid grid-cols-12 bg-gray-100 border-b">
              <th className=" col-span-1 text-start p-2"></th>
              <th className=" col-span-10 text-start p-2">المعلم</th>
              {/* <th className=" col-span-1 text-center p-2">تعديل</th> */}
              <th className=" col-span-1 text-center p-2">حذف</th>
            </tr>
          </thead>
          <tbody>
            {profs.map((e) => {
              return <Row key={e.id} el={e} />;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

import React from "react";
import "./style.css";
import { NavLink, useLocation } from "react-router-dom";

export default function Layout({ children }) {
  let path = useLocation().pathname;

  const Link = (props) => {
    return (
      <div
        className={`my-4 px-8 ${path === `/${props.li}` && "activem "} w-full`}
      >
        <NavLink
          exact
          to={`/${props.li}`}
          className={`py-1 flex items-center px-5 hover:bg-sky-800/30  ${
            path === `/${props.li}` && " active hover:bg-white"
          } hover:rounded-full  `}
        >
          {props.text}
        </NavLink>
      </div>
    );
  };
  return (
    <div>
      <div className=" rtl flex h-screen">
        {/*  */}
        {/* Side Bar */}
        {/*  */}
        <section className=" ml-3 py-28 pr-4  shadow-md rounded-l-2xl text-white bg-[#1a2c41] text-2xl">
          <Link text={"الجداول"} li={""} />
          <Link text={"المستويات"} li={"levels"} />
          <Link text={"الاقسام"} li={"departments"} />
          <Link text={"الكورسات"} li={"courses"} />
          <Link text={"الارتباطات"} li={"related-courses"} />
          <Link text={"المعلمون"} li={"profs"} />
          <div className=" text-base font-bold">
            <Link text={"جداول المعلمون"} li={"profs-sech"} />
          </div>
        </section>
        <section className=" w-full overflow-auto h-full">{children}</section>
      </div>
    </div>
  );
}

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function RelatedCourses() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({
    main: null,
    sec: null,
  });

  const [reload, setReload] = useState(false);
  const openDone = () => {
    setMessage(true);
    setTimeout(() => setMessage(false), 2000);
  };
  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getCourse`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setCourses(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, []);

  const addHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var formdata = new FormData();
    formdata.append("idCourse", course.main);
    formdata.append("idCourseRelation", course.sec);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_URL}/api/auth/AddcourseToCourse`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        openDone();
        setReload(!reload);
        setCourse({ main: null, sec: null });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  };

  // get Data

  const [allCourses, setAllCourses] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getcourseToCourse`).then(
      (res) => {
        if (res.status === 200) {
          setError(false);
          res.json().then((data) => {
            setAllCourses(data);
            setLoad(false);
          });
        } else {
          setLoad(false);
          setError(true);
        }
      }
    );
  }, [reload]);

  //
  // Dlete Level
  //
  const deleteHandeller = (id) => {
    setLoad(true);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_URL}/api/auth/deletecourseToCourse/${id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setReload(!reload);
      }
    });
  };
  return (
    <div className="p-4">
      <h1 className="head">الكورسات المترابطة</h1>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      <div className="form">
        <div className=" min-w-56 p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الكورس الاولي</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={course.main}
              label="الكورس الاولي"
              onChange={(e) => setCourse({ ...course, main: e.target.value })}
            >
              {courses.map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className=" min-w-56 p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              الكورس الثانوي
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={course.sec}
              label="الكورس الثانوي"
              onChange={(e) => setCourse({ ...course, sec: e.target.value })}
            >
              {courses.map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="formItem">
          <button
            onClick={addHandeller}
            disabled={load || !course.sec || !course.main}
            className=" btn "
          >
            {!load ? (
              <>
                {" "}
                اضافة <span className=" text-2xl font-bold">+</span>
              </>
            ) : (
              <div className=" flex ">
                تحميل{" "}
                <div class="dots mx-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </button>
        </div>
      </div>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      {error ? (
        <>
          <h1 className=" text-red-600 text-center my-48 text-4xl">
            هناك مشكلة .....
          </h1>
        </>
      ) : (
        <table className="table">
          <thead>
            <tr className=" grid grid-cols-12 bg-gray-100 border-b">
              <th className=" col-span-5 text-start p-2">الكورس الاولي</th>
              <th className=" col-span-5 text-start p-2">الكورس الثانوي</th>
              {/* <th className=" col-span-1 text-center p-2">تعديل</th> */}
              <th className=" col-span-2 text-center p-2">حذف</th>
            </tr>
          </thead>
          <tbody>
            {allCourses.map((e, index) => {
              return (
                <tr key={index} className=" grid grid-cols-12 border-b">
                  <td className=" col-span-5 text-start p-2">{e.courseName}</td>
                  <td className=" col-span-5 text-start p-2">
                    {e.courseNameRelation}
                  </td>
                  {/* <th className=" col-span-1 text-center p-2">تعديل</th> */}
                  <td className=" col-span-2 text-center p-2">
                    <button
                      disabled={load}
                      onClick={() => deleteHandeller(e.idRelation)}
                      className=" px-3 text-red-700/80 hover:text-red-700"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {/* {message && (
        <h1 className=" text-green-600 text-center my-48 text-4xl">
          تمت الاضافة بنجاح <i class="fa-solid fa-check"></i>
        </h1>
      )} */}
    </div>
  );
}

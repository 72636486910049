import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "./Table";

export default function ProfsSech() {
  const [profs, setProfs] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getProf`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setProfs(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, []);

  const [prof, setProf] = useState({
    name: "",
    year: "",
    term: "",
  });

  const [sech, setSech] = useState([]);
  const searchHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var formdata = new FormData();
    formdata.append("term", prof.term);
    formdata.append("year", prof.year);
    formdata.append("nameProf", prof.name);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_URL}/api/auth/getTimeTeacher`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSech(data);
          setLoad(false);
        });
      } else {
        setLoad(true);
      }
    });
  };
  return (
    <div>
      <h1 className="head">جداول المعلمون</h1>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      <form className="form">
        <div className=" min-w-56 p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">المعلم</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={prof.name}
              label="المعلم"
              onChange={(e) => setProf({ ...prof, name: e.target.value })}
            >
              {profs.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <TextField
            id="outlined-number"
            label="العام الدراسي"
            type="number"
            value={prof.year}
            onChange={(e) => setProf({ ...prof, year: e.target.value })}
          />
        </div>
        <div className=" min-w-56 p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الترم</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={prof.term}
              label="الترم"
              onChange={(e) => setProf({ ...prof, term: e.target.value })}
            >
              <MenuItem value={"one"}>الاول</MenuItem>
              <MenuItem value={"two"}>الثاني</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <button
            onClick={searchHandeller}
            disabled={load || !prof.name || !prof.term || !prof.year}
            className=" btn "
          >
            {!load ? (
              <>
                {" "}
                بحث <i className="fa-solid text-lg fa-magnifying-glass"></i>
              </>
            ) : (
              <div className=" flex ">
                تحميل{" "}
                <div class="dots mx-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </button>
        </div>
      </form>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      {sech.length > 0 && (
        <>
          <Table ta={sech} prof={prof.name} />
        </>
      )}
    </div>
  );
}

import "./utils/all.min.css";
import "./App.css";
import { Route, Routes } from "react-router";
import Profes from "./Pages/profers/Profes";
import Layout from "./components/Layout";
import Sechadules from "./Pages/Sechadules/Sechadules";
import Levels from "./Pages/Levels/Levels";
import Departments from "./Pages/departments/Departments";
import Courses from "./Pages/Courses/Courses";
import RelatedCourses from "./Pages/relatedCourses/RelatedCourses";
import ProfsSech from "./Pages/sech-profs/Profs-Sech";
function App() {
  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/levels" element={<Levels />} />
          <Route path="/departments" element={<Departments />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/related-courses" element={<RelatedCourses />} />
          <Route path="/profs" element={<Profes />} />
          <Route path="/profs-sech" element={<ProfsSech />} />
          {/* <Route path="/sechedules" element={<Sechadules />} /> */}
          <Route path="/" element={<Sechadules />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;

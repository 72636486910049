import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Departments() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [reload, setReload] = useState(false);
  const [section, setSection] = useState("");

  const [departments, setDeparments] = useState([]);
  //
  // Fetching data
  //
  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getDepartment`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setDeparments(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, [reload]);

  //
  // Add Department
  //

  const addHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var formdata = new FormData();
    formdata.append("name", section);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_URL}/api/auth/AddDepartment`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setReload(!reload);
        setSection("");
      }
    });
  };

  //
  // edit Department
  //
  const [edit, setEdit] = useState({
    id: null,
    content: null,
  });

  const openEdit = (el) => {
    setEdit({
      id: el.id,
      content: el.name,
    });
  };

  const editHandeller = () => {
    setLoad(true);
    var formdata = new FormData();
    formdata.append("name", edit.content);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_URL}/api/auth/EditDepartment/${edit.id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setEdit({
          id: null,
          content: null,
        });
        setLoad(false);
        setReload(!reload);
      }
    });
  };

   //
  // Dlete Level
  //
  const deleteHandeller = (id) => {
    setLoad(true);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_URL}/api/auth/deleteDepartment/${id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setReload(!reload);
      }
    });
  };
  return (
    <div className=" p-4">
      <h1 className="head">الأقسام</h1>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      {/*  */}
      {/* Add Department Form */}
      {/*  */}
      <form className="form">
        <div className="formItem">
          <TextField
            value={section}
            onChange={(e) => setSection(e.target.value)}
            id="outlined-basic"
            label="اضافة قسم"
            variant="outlined"
          />
        </div>
        <div className="formItem">
          <button
            onClick={addHandeller}
            disabled={load || !section}
            className=" btn "
          >
            {!load ? (
              <>
                {" "}
                اضافة <span className=" text-2xl font-bold">+</span>
              </>
            ) : (
              <div className=" flex ">
                تحميل{" "}
                <div class="dots mx-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </button>
        </div>
      </form>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      {error ? (
        <>
          <h1 className=" text-red-600 text-center my-48 text-4xl">
            هناك مشكلة .....
          </h1>
        </>
      ) : (
        //
        // Levles Table
        //
        <table className=" my-3 border  shadow-md bg-white min-w-full">
          <thead>
            <tr className=" grid grid-cols-12 bg-gray-100 border-b">
              <th className=" col-span-10 text-start p-2">القسم</th>
              <th className=" col-span-1 text-center p-2">تعديل</th>
              <th className=" col-span-1 text-center p-2">حذف</th>
            </tr>
          </thead>
          <tbody>
            {departments.map((e) => {
              return (
                <tr key={e.id} className=" grid grid-cols-12 border-b">
                  <td className=" col-span-10 text-start p-2">
                    {edit.id === e.id ? (
                      <div>
                        <input
                          autoFocus
                          type="text"
                          className=" text-sky-800 outline-none p-1 border-b w-96 border-sky-800"
                          value={edit.content}
                          onChange={(e) =>
                            setEdit({ ...edit, content: e.target.value })
                          }
                        />
                        <button
                          disabled={load}
                          onClick={editHandeller}
                          className="btnsm mx-1"
                        >
                          تعديل
                        </button>
                        <button
                          disabled={load}
                          onClick={() =>
                            setEdit({
                              id: null,
                              content: null,
                            })
                          }
                          className="btnsmgr mx-1"
                        >
                          الغاء
                        </button>
                      </div>
                    ) : (
                      <>{e.name}</>
                    )}
                  </td>
                  <td className=" col-span-1 text-center p-2">
                    <button disabled={load} onClick={() => openEdit(e)}>
                      <i className="fa-solid text-green-700/80 hover:text-green-700 fa-pen-to-square"></i>
                    </button>
                  </td>
                  <td className=" col-span-1 text-center p-2">
                    <button
                      disabled={load}
                      onClick={() => deleteHandeller(e.id)}
                      className=" px-3 text-red-700/80 hover:text-red-700"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

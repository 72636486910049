import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sechaduel from "../../components/sechaduel/Sechaduel";

export default function Sechadules() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [choose, setChoose] = useState({
    level: "",
    term: "",
    department: "",
    group: null,
    subGroup: null,
    year: null,
  });

  // optins
  const [levels, setLevels] = useState([]);
  const [department, setDeparments] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URL}/api/auth/getDepartment`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setDeparments(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });

    fetch(`${process.env.REACT_APP_URL}/api/auth/getLevel`).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLevels(data);
          setLoad(false);
          setError(false);
        });
      } else {
        setError(true);
        setLoad(false);
      }
    });
  }, []);

  // /////////////////// /////////////////// /////////////////// ///////////////////

  // Tables
  //

  const [days, setDays] = useState([]);

  const searchHandeller = (e) => {
    e.preventDefault();
    setLoad(true);

    var formdata = new FormData();
    formdata.append("level", choose.level);
    formdata.append("term", choose.term);
    formdata.append("year", choose.year);
    formdata.append("department", choose.department);
    formdata.append("group", choose.subGroup);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_URL}/api/auth/getByTermAndYear`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          let numOfDays = 0;
          data.map((e) => {
            numOfDays = numOfDays + e.length;
          });
          if (numOfDays > 0) {
            setLoad(false);
            setError(false);
            setDays(data);
          } else {
            var formdata = new FormData();
            formdata.append("group", choose.group);
            formdata.append("department", choose.department);
            formdata.append("level", choose.level);
            formdata.append("term", choose.term);
            formdata.append("subGroub", choose.subGroup);
            formdata.append("year", choose.year);

            var requestOptions = {
              method: "POST",
              body: formdata,
              redirect: "follow",
            };
            fetch(
              `${process.env.REACT_APP_URL}/api/auth/CreateAutoSchedule`,
              requestOptions
            ).then((res) => {
              if (res.status === 200) {
                var formdata = new FormData();
                formdata.append("level", choose.level);
                formdata.append("term", choose.term);
                formdata.append("year", choose.year);
                formdata.append("department", choose.department);
                formdata.append("group", choose.subGroup);

                var requestOptions = {
                  method: "POST",
                  body: formdata,
                  redirect: "follow",
                };

                fetch(
                  `${process.env.REACT_APP_URL}/api/auth/getByTermAndYear`,
                  requestOptions
                ).then((res) => {
                  res.json().then((data) => {
                    setLoad(false);
                    setError(false);
                    setDays(data);
                  });
                });
              }
            });

            // fetch(
            //   `${process.env.REACT_APP_URL}/api/auth/CreateAutoSchedule`,
            //   requestOptions2
            // ).then((res) => {
            //   if (res.status === 200) {
            //     // setLoad(false)
            //     console.log("kkkk");
            //     setError(false);
            //   } else {
            //     setLoad(false);
            //     setError(true);
            //   }
            // });
          }
        });
      } else {
        setLoad(false);
        setError(true);
      }
    });
  };

  const deleteSchedulesHandeller = () => {
    setLoad(true);
    const formdata = new FormData();
    formdata.append("department", choose.department);
    formdata.append("level", choose.level);
    formdata.append("term", choose.term);
    formdata.append("year", choose.year);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_URL}/api/auth/deleteAutoSchedule`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setDays([])
      }
    });
  };

  return (
    <div className="p-4">
      <h1 className="head"> الجداول</h1>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      <form className="form">
        <div className=" min-w-56 p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">المستوى</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={choose.level}
              label="المستوى"
              onChange={(e) => setChoose({ ...choose, level: e.target.value })}
            >
              {levels.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الترم</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={choose.term}
              label="الترم"
              onChange={(e) => setChoose({ ...choose, term: e.target.value })}
            >
              <MenuItem value={"one"}>الاول</MenuItem>
              <MenuItem value={"two"}>الثاني</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">القسم</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={choose.department}
              label="القسم"
              onChange={(e) =>
                setChoose({ ...choose, department: e.target.value })
              }
            >
              {department.map((e) => (
                <MenuItem key={e.id} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="min-w-56  p-2">
          <TextField
            id="outlined-number"
            label="الشعبة نظري"
            type="number"
            value={choose.group}
            onChange={(e) => setChoose({ ...choose, group: e.target.value })}
          />
        </div>
        <div className="min-w-56  p-2">
          <TextField
            id="outlined-number"
            label="الشعبة عملي"
            type="number"
            value={choose.subGroup}
            onChange={(e) => setChoose({ ...choose, subGroup: e.target.value })}
          />
        </div>
        <div className="min-w-56  p-2">
          <TextField
            id="outlined-number"
            label="العام الدراسي"
            type="number"
            value={choose.year}
            onChange={(e) => setChoose({ ...choose, year: e.target.value })}
          />
        </div>
        <div className="min-w-56  p-2">
          <button
            onClick={searchHandeller}
            disabled={
              load ||
              !choose.level ||
              !choose.term ||
              !choose.department ||
              !choose.group ||
              !choose.subGroup ||
              !choose.year
            }
            className=" btn "
          >
            {!load ? (
              <>
                {" "}
                بحث <i className="fa-solid text-lg fa-magnifying-glass"></i>
              </>
            ) : (
              <div className=" flex ">
                تحميل{" "}
                <div class="dots mx-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </button>
        </div>
      </form>
      <hr className=" w-3/4 mx-auto my-4"></hr>
      <button onClick={deleteSchedulesHandeller} className=" btnDe">
        حذف هذه الجداول
      </button>

      {error ? (
        <>
          <h1 className=" text-red-600 text-center my-48 text-4xl">
            هناك مشكلة .....
          </h1>
        </>
      ) : (
        <>
          {days.map((e, index) => {
            return (
              <div key={index}>
                <Sechaduel
                  group={index}
                  level={choose.level}
                  term={choose.term}
                  year={choose.year}
                  ta={e}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
